import { Link } from "react-router-dom";
import type { IconType } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as LuIcons from "react-icons/lu";
import * as SiIcons from "react-icons/si";
import React from "react";

interface CardProps {
  title: string;
  icon?: string;
  href?: string;
  horizontal?: boolean;
  children?: React.ReactNode;
}

const iconMap: Record<string, IconType> = {
  ...LuIcons,
  ...FaIcons,
  ...SiIcons,
};

export function Card({ title, href, icon, horizontal, children }: CardProps) {
  let Icon: IconType | undefined = undefined;

  if (typeof icon === "string" && iconMap[icon]) {
    Icon = iconMap[icon];
  } else {
    console.warn(`Icon "${icon}" not found. Check your Markdoc attribute.`);
  }

  const content = (
    <div
      className={`px-6 py-5 rounded-lg border border-border bg-background2 [&_p]:m-0 
        ${horizontal ? "flex items-center gap-4" : ""}
        ${href ? "hover:border-primary/40" : ""}`}
    >
      {Icon && (
        <div className={`${horizontal ? "mb-0" : "mb-3"}`}>
          <Icon size={24} className="text-primary" />
        </div>
      )}

      <h3
        className={`text-base text-textPrimary font-headers 
          ${horizontal ? "mb-0" : "mb-1"}`}
      >
        {title}
      </h3>
      {children}
    </div>
  );

  if (href) {
    const isInternal = href.startsWith("/");
    if (isInternal) {
      return <Link to={href}>{content}</Link>;
    } else {
      return (
        <a href={href} rel="noopener noreferrer">
          {content}
        </a>
      );
    }
  }

  return content;
}
