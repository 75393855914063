import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Docs } from "./components/pages/Docs";
import { sitemap, NavGroup, NavLink } from "./sitemap";

export function App() {
  const flattenSitemap = (groups: NavGroup[]): NavLink[] => {
    return groups.flatMap((group) => group.links);
  };

  const allDocs = flattenSitemap(sitemap);

  return (
    <Router>
      <Routes>
        {allDocs.map((doc, index) => {
          const prevDoc = allDocs[index - 1];
          const nextDoc = allDocs[index + 1];
          return (
            <Route
              key={doc.path}
              path={doc.path}
              element={
                <Docs
                  markdownPath={doc.markdown ?? ""}
                  previousPage={
                    prevDoc
                      ? { title: prevDoc.title, href: prevDoc.path ?? "" }
                      : undefined
                  }
                  nextPage={
                    nextDoc
                      ? { title: nextDoc.title, href: nextDoc.path ?? "" }
                      : undefined
                  }
                />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/overview" />} />
      </Routes>
    </Router>
  );
}
