import { LuCircleAlert, LuInfo } from "react-icons/lu";

const CALLOUT_STYLES = {
  info: {
    Icon: LuInfo,
    wrapperColors: "bg-info/10 border-info/20 [&_p]:text-info",
    iconColor: "text-info",
  },
  warning: {
    Icon: LuCircleAlert,
    wrapperColors: "bg-warning/10 border-warning/20 [&_p]:text-warning",
    iconColor: "text-warning",
  },
} as const;

type CalloutType = keyof typeof CALLOUT_STYLES;

interface CalloutBlockProps {
  children: React.ReactNode;
  type: CalloutType;
}

export function Callout({ children, type }: CalloutBlockProps) {
  const { Icon, wrapperColors, iconColor } = CALLOUT_STYLES[type];

  return (
    <div
      className={`flex gap-3 px-5 py-4 mb-6 rounded-lg [&_p]:m-0 border [&_a]:text-textPrimary [&_a]:underline ${wrapperColors}`}
    >
      <Icon size={16} className={`flex-shrink-0 m-1 ${iconColor}`} />
      {children}
    </div>
  );
}
