import { LuTableProperties } from "react-icons/lu";
import { TOP_NAV_HEIGHT } from "./TopNav";
import { useState, useEffect } from "react";

export function TableOfContents({ content }: { content: any }) {
  const [activeId, setActiveId] = useState("");
  const nodes = content?.children || [];
  const headings = getHeadings(nodes);

  useEffect(() => {
    if (headings.length === 0) return;
    const handleScroll = () => {
      const headingElements = headings.map((h) =>
        document.getElementById(h.id)
      );

      const isNearBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100;

      if (isNearBottom) {
        const lastHeading = headingElements[headingElements.length - 1];
        if (lastHeading) {
          setActiveId(lastHeading.id);
        }
        return;
      }

      const firstVisibleHeading = headingElements.find((el) => {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        return rect.top >= TOP_NAV_HEIGHT;
      });

      if (firstVisibleHeading) {
        setActiveId(firstVisibleHeading.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [headings]);

  if (headings.length === 0)
    return <nav className="w-[240px] hidden xl:block" />;

  return (
    <nav
      style={{ top: TOP_NAV_HEIGHT }}
      className="sticky w-[240px] self-start pt-16 hidden xl:block"
    >
      <div className="flex items-center gap-2 mb-4">
        <LuTableProperties size={12} className="text-textSecondary" />
        <p className="text-sm font-light font-headers text-textSecondary">
          On This Page
        </p>
      </div>
      <ul className="space-y-2">
        {headings.map((heading) => (
          <li key={heading.id}>
            <a
              className={`block font-body font-light text-sm ${getIndent(
                heading.level
              )} ${
                heading.id === activeId
                  ? "text-primary"
                  : "text-textSecondary hover:text-textPrimary"
              }`}
              href={`#${heading.id}`}
            >
              {heading.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

interface HeadingNode {
  title: string;
  id: string;
  level: number;
}

function getHeadings(nodes: any[]) {
  const headings: HeadingNode[] = [];
  // Don't include the h1 heading
  nodes.forEach((node) => {
    if (["h2", "h3", "h4"].includes(node.name)) {
      const title = node.children[0];
      const id = node.attributes.id;
      const level = parseInt(node.name[1]);
      headings.push({ title, id, level });
    }
  });
  return headings;
}

function getIndent(level: number) {
  if (level === 2) return "";
  if (level === 3) return "ml-4";
  if (level === 4) return "ml-8";
  return "";
}
