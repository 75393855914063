import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { IconButton } from "../basics/IconButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaGithub, FaLinkedin, FaDiscord } from "react-icons/fa";

const THEMES = ["orange", "blue", "red", "green"] as const;
type ThemeColor = (typeof THEMES)[number];

interface FooterLink {
  title: string;
  href: string;
}

interface FooterProps {
  previousPage?: FooterLink;
  nextPage?: FooterLink;
}

export function Footer({ previousPage, nextPage }: FooterProps) {
  const [currentTheme, setCurrentTheme] = useState<ThemeColor>(() => {
    const saved = localStorage.getItem("theme");
    return (saved as ThemeColor) || "orange";
  });

  const updateTheme = (theme: ThemeColor) => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
    setCurrentTheme(theme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") as ThemeColor;
    if (savedTheme) {
      updateTheme(savedTheme);
    }
  }, []);

  return (
    <div className="mt-16">
      <div className="flex justify-between items-center pb-6 border-b border-border">
        <div>
          {previousPage && (
            <Link
              to={previousPage.href}
              className="flex items-center gap-2 text-sm font-headers text-textSecondary hover:text-textPrimary"
            >
              <LuChevronLeft size={16} />
              {previousPage.title}
            </Link>
          )}
        </div>
        <div>
          {nextPage && (
            <Link
              to={nextPage.href}
              className="flex items-center gap-2 text-sm font-headers text-textSecondary hover:text-textPrimary"
            >
              {nextPage.title}
              <LuChevronRight size={16} />
            </Link>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex gap-2 py-8">
          <IconButton
            variant="text"
            icon={FaGithub}
            onClick={() =>
              window.open("https://github.com/vigilant-run", "_blank")
            }
          />
          <IconButton
            variant="text"
            icon={FaLinkedin}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/vigilantrun/",
                "_blank"
              )
            }
          />
          <IconButton
            variant="text"
            icon={FaDiscord}
            onClick={() =>
              window.open("https://discord.gg/Kr9YR2XUqr", "_blank")
            }
          />
        </div>

        <div className="flex gap-2 mx-3">
          {THEMES.map((theme) => (
            <button
              key={theme}
              className="w-4 h-4 flex items-center justify-center hover:scale-110 transition-transform"
              onClick={() => updateTheme(theme)}
            >
              <div
                data-theme={theme}
                className={`w-3 h-3 ${
                  currentTheme === theme
                    ? "bg-primary"
                    : "border border-primary"
                } transition-transform`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
