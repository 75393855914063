import { Link } from "react-router-dom";
import { Button } from "../basics/Button";
import { LuMenu } from "react-icons/lu";
import { IconButton } from "../basics/IconButton";
import { useState } from "react";
import { MobileNav } from "./MobileNav";

export const TOP_NAV_HEIGHT = 68;

export function TopNav() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <>
      <div
        style={{ height: TOP_NAV_HEIGHT }}
        className="fixed top-0 left-0 w-full bg-background2/90 backdrop-blur border-b border-border p-4 flex items-center justify-between z-50"
      >
        <Link
          to={`${process.env.REACT_APP_WEBSITE_URL}`}
          className="block text-xl font-headers text-textPrimary mx-3 py-0.5"
        >
          Vigilant <span className="text-primary">Docs</span>
        </Link>
        <div className="hidden lg:flex items-center gap-4">
          <Button
            variant="outline"
            onClick={() =>
              (window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`)
            }
          >
            Help
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              (window.location.href = process.env.REACT_APP_DASHBOARD_URL || "")
            }
          >
            Launch App
          </Button>
        </div>
        <IconButton
          className={"lg:hidden"}
          icon={LuMenu}
          onClick={() => setIsMobileNavOpen(true)}
        />
      </div>
      <MobileNav isOpen={isMobileNavOpen} setIsOpen={setIsMobileNavOpen} />
    </>
  );
}
