import { useState, useEffect } from "react";

interface UseDocContentReturn {
  content: any;
  error: string | null;
  isLoading: boolean;
}

export function useDocContent(path: string): UseDocContentReturn {
  const [content, setContent] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(`/api/content?path=${path}`);

        if (!response.ok) {
          throw new Error(
            `error fetching doc content - status: ${response.status}`
          );
        }

        const data = await response.json();
        setContent(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to fetch content"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, [path]);

  return { content, error, isLoading };
}
