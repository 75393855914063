import type { IconType } from "react-icons";

interface IconButtonProps {
  icon: IconType;
  onClick?: () => void;
  className?: string;
  variant?: "default" | "text";
}

export function IconButton({
  icon: Icon,
  onClick,
  className,
  variant = "default",
}: IconButtonProps) {
  const getVariantStyles = () => {
    switch (variant) {
      case "default":
        return "text-textPrimary hover:bg-inputTint";
      case "text":
        return "text-textSecondary hover:text-textPrimary";
      default:
        return "";
    }
  };

  return (
    <button
      onClick={onClick}
      className={`p-2 rounded ${getVariantStyles()} ${className || ""}`}
    >
      <Icon size={20} />
    </button>
  );
}
