import { Link, useLocation } from "react-router-dom";
import { Button } from "../basics/Button";
import { sitemap } from "../../sitemap";
import { TOP_NAV_HEIGHT } from "./TopNav";

export const SIDEBAR_WIDTH = 240;

export function Sidebar() {
  const location = useLocation();

  return (
    <div
      style={{ top: TOP_NAV_HEIGHT }}
      className={`fixed left-0 w-[${SIDEBAR_WIDTH}px] h-screen pt-4 px-4 flex-shrink-0 bg-background2 border-r border-border lg:block hidden`}
    >
      {sitemap.map((group) => (
        <div key={group.title} className="p-4 flex flex-col gap-1">
          <p className="font-nav text-md font-medium text-textPrimary mb-2">
            {group.title}
          </p>
          {group.links.map((link) => (
            <Link key={link.path} to={link.path} className="block">
              <Button
                variant="outline"
                className="w-full justify-start"
                selected={link.path === location.pathname}
              >
                {link.title}
              </Button>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
}
