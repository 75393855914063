export interface NavLink {
  title: string;
  path: string;
  markdown: string;
}

export interface NavGroup {
  title: string;
  links: NavLink[];
}

export const sitemap: NavGroup[] = [
  {
    title: "Intro",
    links: [
      {
        title: "What is Vigilant?",
        path: "/overview",
        markdown: "/overview.md",
      },
    ],
  },
  {
    title: "Concepts",
    links: [
      {
        title: "Structured Logs",
        path: "/concepts/structuredLogs",
        markdown: "/concepts/structuredLogs.md",
      },
      {
        title: "Alerts",
        path: "/concepts/alerts",
        markdown: "/concepts/alerts.md",
      },
    ],
  },
  {
    title: "Quick Start",
    links: [
      {
        title: "Node.js",
        path: "/quickStart/nodejs",
        markdown: "/quickStart/nodejs.md",
      },
      {
        title: "Python",
        path: "/quickStart/python",
        markdown: "/quickStart/python.md",
      },
      {
        title: "Go",
        path: "/quickStart/go",
        markdown: "/quickStart/go.md",
      },
    ],
  },
  {
    title: "Documentation",
    links: [
      {
        title: "Node.js",
        path: "/documentation/nodejs",
        markdown: "/documentation/nodejs.md",
      },
      {
        title: "Python",
        path: "/documentation/python",
        markdown: "/documentation/python.md",
      },
      {
        title: "Go",
        path: "/documentation/go",
        markdown: "/documentation/go.md",
      },
    ],
  },
];
