import { useDocContent } from "../../hooks/useDocContent";
import Markdoc from "@markdoc/markdoc";
import React, { useEffect, useState } from "react";
import { Footer } from "../layouts/Footer";
import { TableOfContents } from "../layouts/TableOfContents";
import { TOP_NAV_HEIGHT, TopNav } from "../layouts/TopNav";
import { Sidebar, SIDEBAR_WIDTH } from "../layouts/Sidebar";
import { markdocComponents as components } from "../../markdoc/componentMap";
import { useScrollToTop } from "../../hooks/useScrollToTop";

interface DocsProps {
  markdownPath: string;
  nextPage?: { title: string; href: string };
  previousPage?: { title: string; href: string };
}

export function Docs({
  markdownPath: markdown,
  nextPage,
  previousPage,
}: DocsProps) {
  useScrollToTop();
  const { content, error, isLoading } = useDocContent(markdown);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 1024);

  const loadingContent = (
    <div className="h-screen">
      <h2 className="text-textPrimary">Loading...</h2>
    </div>
  );
  const errorContent = (
    <div className="h-screen">
      <h2 className="text-textPrimary">Error: {error}</h2>
    </div>
  );
  const renderedContent = (
    <>{content && Markdoc.renderers.react(content, React, { components })}</>
  );

  let displayContent = isLoading ? loadingContent : renderedContent;
  if (error) displayContent = errorContent;

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        paddingLeft: isSidebarOpen ? SIDEBAR_WIDTH : 0,
        paddingTop: TOP_NAV_HEIGHT,
      }}
    >
      <TopNav />
      <Sidebar />
      <main className="flex justify-center">
        <div className="flex-1 w-full max-w-[900px] lg:p-16 md:p-8 px-4 py-12 prose prose-invert">
          {displayContent}
          <Footer nextPage={nextPage} previousPage={previousPage} />
        </div>
        <TableOfContents content={content} />
      </main>
    </div>
  );
}
