import { ReactNode } from "react";
import type { IconType } from "react-icons";

interface ButtonProps {
  children: ReactNode;
  icon?: IconType;
  variant?: "primary" | "outline";
  selected?: boolean;
  onClick?: () => void;
  className?: string;
}

export function Button({
  children,
  icon: Icon,
  variant = "primary",
  selected = false,
  onClick,
  className,
}: ButtonProps) {
  const baseStyles =
    "flex items-center gap-2 rounded px-3 py-1.5 text-sm font-headers";

  const getVariantStyles = () => {
    switch (variant) {
      case "primary":
        return `bg-primary/10 text-primary border border-primary/20 hover:bg-primary/20`;
      case "outline":
        return `${
          selected
            ? "bg-primary/10 text-primary border border-primary/20"
            : "text-textSecondary border border-transparent hover:bg-inputTint hover:text-textPrimary"
        }`;
      default:
        return "";
    }
  };

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${getVariantStyles()} ${className || ""}`}
    >
      {Icon && <Icon size={16} />}
      {children}
    </button>
  );
}
