import { Link, useLocation } from "react-router-dom";
import { Button } from "../basics/Button";
import { LuX } from "react-icons/lu";
import { sitemap } from "../../sitemap";
import { IconButton } from "../basics/IconButton";

interface MobileNavProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function MobileNav({ isOpen, setIsOpen }: MobileNavProps) {
  const location = useLocation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-background z-50 lg:hidden">
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-border">
          <Link
            to="/"
            className="block text-xl font-headers text-textPrimary mx-3 py-0.5"
            onClick={() => setIsOpen(false)}
          >
            Vigilant <span className="text-primary">Docs</span>
          </Link>
          <IconButton icon={LuX} onClick={() => setIsOpen(false)} />
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto p-4">
          {sitemap.map((group) => (
            <div key={group.title} className="mb-8 space-y-1">
              <p className="font-nav text-md font-medium text-textPrimary">
                {group.title}
              </p>
              {group.links.map((link) => (
                <Link
                  key={link.path}
                  to={link.path}
                  className="block"
                  onClick={() => setIsOpen(false)}
                >
                  <Button
                    variant="outline"
                    className="w-full justify-start"
                    selected={link.path === location.pathname}
                  >
                    {link.title}
                  </Button>
                </Link>
              ))}
            </div>
          ))}
        </nav>

        {/* Bottom Buttons */}
        <div className="p-4 border-t border-border space-y-2">
          <Button
            variant="outline"
            className="w-full justify-center"
            onClick={() =>
              (window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`)
            }
          >
            Support
          </Button>
          <Button
            variant="primary"
            className="w-full justify-center"
            onClick={() =>
              (window.location.href = process.env.REACT_APP_DASHBOARD_URL || "")
            }
          >
            Launch App
          </Button>
        </div>
      </div>
    </div>
  );
}
