import { useEffect, useRef, useState } from "react";
import Prism from "prismjs";
import "prism-themes/themes/prism-vsc-dark-plus.css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-python";
import "prismjs/components/prism-go";
import "prismjs/components/prism-bash";
import { LuCheck, LuCopy } from "react-icons/lu";

interface CodeSnippet {
  title: string;
  language: string;
  content: string;
}

export const CodeGroup = ({
  tabs,
  children,
}: {
  tabs: Array<string>;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const childrenArray = Array.isArray(children) ? children : [children];

  const snippets: CodeSnippet[] = childrenArray.map(
    (child: React.ReactElement, index: number) => {
      const title = tabs[index];
      return {
        title,
        language: child.props["data-language"],
        content: child.props.children,
      };
    }
  );

  const [activeTab, setActiveTab] = useState(snippets[0]);
  const [copied, setCopied] = useState(false);
  const codeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [activeTab]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(activeTab.content);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  if (snippets.length === 0) return null;

  return (
    <div className="mb-6 rounded-lg border border-codeBorder flex flex-col bg-codeTabsBackground overflow-hidden">
      <div className="flex justify-between items-center">
        <div className="flex overflow-x-auto">
          {snippets.map((snippet, index) => {
            const isActive = activeTab.title === snippet.title;
            const activeClass = "text-textPrimary bg-codeBackground";
            const inactiveClass = "text-textSecondary";

            return (
              <button
                key={index}
                className={`text-xs px-4 py-3 box-border hover:text-textPrimary ${
                  isActive ? activeClass : inactiveClass
                }`}
                onClick={() => setActiveTab(snippet)}
              >
                {snippet.title}
              </button>
            );
          })}
        </div>
        <button
          onClick={handleCopy}
          className="p-2 mr-1 h-8 w-8 hover:bg-inputTint rounded-lg transition-colors"
        >
          {copied ? (
            <LuCheck className="w-4 h-4 text-green-500" />
          ) : (
            <LuCopy className="w-4 h-4 text-gray-400" />
          )}
        </button>
      </div>
      <div className="overflow-x-auto relative">
        {snippets.map((snippet) => (
          <pre
            key={snippet.title}
            className={`px-5 py-4 bg-codeBackground ${
              activeTab.title === snippet.title ? "block" : "hidden"
            }`}
          >
            <code
              ref={activeTab.title === snippet.title ? codeRef : undefined}
              className={`language-${snippet.language}`}
            >
              {snippet.content}
            </code>
          </pre>
        ))}
      </div>
    </div>
  );
};
