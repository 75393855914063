interface CardGridProps {
  children: React.ReactNode;
  columns?: number;
}

export function CardGrid({ children, columns = 2 }: CardGridProps) {
  const columnClass =
    {
      1: "grid-cols-1",
      2: "grid-cols-1 md:grid-cols-2",
      3: "grid-cols-1 md:grid-cols-3",
      4: "grid-cols-1 md:grid-cols-2 lg:grid-cols-4",
    }[columns] || "grid-cols-1 md:grid-cols-2";

  return <div className={`grid gap-4 ${columnClass}`}>{children}</div>;
}
