import { Callout } from "./components/CalloutBlocks";
import { Card } from "./components/Card";
import { CardGrid } from "./components/CardGrid";
import { CodeGroup } from "./components/CodeBlock";
import { Pill } from "./components/Pill";

export const markdocComponents = {
  Card,
  CardGrid,
  Pill,
  CodeGroup,
  Callout,
};
